.shortcut-help {
  position: relative;

  /* background-color: var(--blue-color); */
  border-radius: 0.3em;
  font-size: 0.7em;
  line-height: 1;
  height: 1.5em;
  /* display: inline-block; */

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.3em;
  margin-left: 0.5em;
  /* border: solid 1px var(--blue-color); */

  /* padding: 0.5em .7em; */
  /* width: 0; */
  /* transform: translateX(0.3em); */

  border: solid 1px color-mix(in srgb, var(--color-black) 30%, white);
  color: color-mix(in srgb, var(--color-black) 30%, white);
}

/* .app--hide-keyboard .shortcut-help {
  transition-delay: 0;
}

.app--show-keyboard .shortcut-help {
  display: inline-block;
  opacity: 1;
}

.shortcut-help__inner {
  display: inline-block;
  position: relative;

  color: white;
} */

/* .shortcut-help__inner::before {
  background-color: var(--blue-color);
  border-radius: 0.3em;
  content: "";
  position: absolute;
  left: -0.3em;
  top: -0.3em;
  width: calc(100% + 0.6em);
  height: calc(100% + 0.6em);
}

.shortcut-help__text {
  position: relative;
} */
