.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  /* pointer-events: none; */

  /* gap: 1rem; */
}

.overlay__inner {
  /* position: absolute; */

  margin-top: calc(2 * var(--layout-padding-left-right));
  width: calc(100% - 2 * var(--layout-padding-left-right));

  /* height: 100%; */

  background-color: white;
  padding: var(--layout-padding-left-right);

  height: calc(100% - 4 * var(--layout-padding-left-right));

  /* margin: 4rem;
   */
}
