.assistant {
  position: absolute;
  bottom: 2em;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
}

.assistant__sub-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  width: 48em;
  background-color: red;
  transform: translate(-50%, -100%);
}
.assistant__sub-menu--active {
  display: block;
}

.assistant__sub-menu-items {
  display: flex;
  /* gap: 1.5em; */
  flex-wrap: nowrap;
  justify-content: space-between;
}

.assistant__sub-menu-item {
  white-space: nowrap;
}

.assistant__icon {
  margin-right: 0.5em;
}

.assistant__inner {
  display: flex;
  align-items: center;
}
.assistant p {
  margin-left: 1em;
}

.assistant__button {
  /* background-color: var(--blue-color); */
}
