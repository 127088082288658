.textfield {
  tab-size: 4ch;
  height: 100%;
  overflow: auto;
  margin: 0;
}

.textfield__inner {
  /* background-color: #fafafa; */
  border-radius: 1em;
  position: relative;
  text-align: left;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 100%;
}

.textfield__textarea,
.textfield__text {
  top: 0;
  left: 0;
  height: 100%;
  color: inherit;
  padding: 0;
  margin: 0;
  padding-top: 1em;
  box-sizing: border-box;

  --center: max(
    var(--layout-padding-left-right),
    calc(50% - var(--layout-max-width) / 2)
  );

  left: var(--center);
  /* right: var(--center); */

  width: calc(100% - 2 * var(--center));
}

.textfield__textarea {
  position: absolute;
  resize: none; /*remove the resize handle on the bottom right*/

  color: transparent;
  /* -webkit-text-fill-color: transparent; */

  caret-color: var(--color-black);
  z-index: 9;
}

.textfield__textarea::selection {
  color: var(--c-selection-color) !important;
  background: var(--c-selection-bg) !important;
}

.textfield__text {
  /* color: transparent; */

  position: relative;
  pointer-events: none;
}

.textfield__text-line {
  position: relative;
}

.textfield__text-line-nr,
.textfield__text-nl {
  color: var(--gray-dark-color);
  display: inline-block;
  line-height: 0;
}

.textfield__text-line-nr {
  position: absolute;
  text-align: right;
  left: 0;
  transform: translateX(calc(-100% - 0.35em));

  font-size: 0.8em;
  top: 1.05em;
  /* margin-top: 0.2em; */
}

.textfield__text-nl {
  width: 0;
  color: var(--gray-color);
  display: inline-block;

  transform: translateX(0.05em);
}
