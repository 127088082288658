@import url("https://fonts.signalwerk.ch/css/latest/family=Work+Sans:ital,wght@0,100..900;1,100..900.css");
@import url("https://fonts.signalwerk.ch/css/latest/family=IBM+Plex+Mono:ital,wght@0,400..700;1,400..700.css");
@import url("https://fonts.signalwerk.ch/css/latest/family=Noto+Emoji:wght@300..700.css");

@font-face {
  font-family: "editor";
  src: url("./fonts/fontello/font/editor.woff2") format("woff2"),
    url("./fonts/fontello/font/editor.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before {
  font-family: "editor";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
}

.icon-ok:before {
  content: "\e800";
} /* '' */
.icon-language:before {
  content: "\e802";
} /* '' */
.icon-resize-normal:before {
  content: "\e804";
} /* '' */
.icon-resize-full:before {
  content: "\e805";
} /* '' */
.icon-gauge:before {
  content: "\e807";
} /* '' */
.icon-ccw:before {
  content: "\e808";
} /* '' */
.icon-magic:before {
  content: "\f0d0";
} /* '' */
.icon-comment-empty:before {
  content: "\f0e5";
} /* '' */
.icon-exchange:before {
  content: "\f0ec";
} /* '' */
.icon-terminal:before {
  content: "\f120";
} /* '' */

:root {
  --work-sans-fea-aalt: "aalt" off;
  --work-sans-fea-c2sc: "c2sc" off;
  --work-sans-fea-calt: "calt" on;
  --work-sans-fea-case: "case" off;
  --work-sans-fea-dlig: "dlig" off;
  --work-sans-fea-dnom: "dnom" off;
  --work-sans-fea-frac: "frac" off;
  --work-sans-fea-hist: "hist" off;
  --work-sans-fea-liga: "liga" on;
  --work-sans-fea-lnum: "lnum" off;
  --work-sans-fea-locl: "locl" off;
  --work-sans-fea-nalt: "nalt" off;
  --work-sans-fea-numr: "numr" off;
  --work-sans-fea-onum: "onum" off;
  --work-sans-fea-ordn: "ordn" off;
  --work-sans-fea-ornm: "ornm" off;
  --work-sans-fea-pnum: "pnum" off;
  --work-sans-fea-rvrn: "rvrn" off;
  --work-sans-fea-salt: "salt" off;
  --work-sans-fea-sinf: "sinf" off;
  --work-sans-fea-smcp: "smcp" off;
  --work-sans-fea-ss02: "ss02" off;
  --work-sans-fea-ss03: "ss03" off;
  --work-sans-fea-ss04: "ss04" off;
  --work-sans-fea-ss05: "ss05" off;
  --work-sans-fea-subs: "subs" off;
  --work-sans-fea-sups: "sups" off;
  --work-sans-fea-tnum: "tnum" off;
  --work-sans-fea-zero: "zero" off;
  --work-sans-fea-cpsp: "cpsp" off;
  --work-sans-fea-kern: "kern" on;

  --work-sans-weight-normal: 400;
  --work-sans-weight-bold: 700;
  --work-sans-weight-black: 900;

  --green-color: #006984; /* c: 100 m: 0 y: 20 k: 65 */
  --blue-color: #0054a2; /* c: 100 m: 70 y: 10 k: 52 */
  --gray-color: #e6e8ea;
  --gray-dark-color: #c7c7c7;
  /* --brown-color: rgb(178, 112, 0); */
  --color-brown: #d6b425;

  --gray-red: #f00; /* c: 0 m: 100 y: 100 k: 0 */
  --color-black: #121212;

  --c-selection-bg: var(--blue-color);
  --c-selection-color: var(--gray-color);
  --c-black: var(--color-black);

  --layout-max-width: 40em;
  --layout-padding-left-right: 1.5em;

  --font-size--default: 15px;
  --font-size--m: 17px;
  --font-size--l: 19px;

  /* setting */
  font-size: var(--font-size--default);
  height: 100%;
  box-sizing: border-box;
  tab-size: 4ch;
}

@media screen and (min-width: 750px) {
  :root {
    font-size: var(--font-size--m);
  }
}

@media screen and (min-width: 1100px) {
  :root {
    font-size: var(--font-size--l);
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  font: inherit; /* Get rid of all font sizes and heights */
}

::selection {
  color: var(--c-selection-color);
  background: var(--c-selection-bg);
}

html,
body,
#root,
#root > div {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Work Sans", "Noto Emoji", sans-serif;
  font-weight: var(--work-sans-weight-normal);
  line-height: 1.6;
  min-height: 100%;

  font-feature-settings: var(--work-sans-fea-aalt), var(--work-sans-fea-c2sc),
    var(--work-sans-fea-calt), var(--work-sans-fea-case),
    var(--work-sans-fea-dlig), var(--work-sans-fea-dnom),
    var(--work-sans-fea-frac), var(--work-sans-fea-hist),
    var(--work-sans-fea-liga), var(--work-sans-fea-lnum),
    var(--work-sans-fea-locl), var(--work-sans-fea-nalt),
    var(--work-sans-fea-numr), var(--work-sans-fea-onum),
    var(--work-sans-fea-ordn), var(--work-sans-fea-ornm),
    var(--work-sans-fea-pnum), var(--work-sans-fea-rvrn),
    var(--work-sans-fea-salt), var(--work-sans-fea-sinf),
    var(--work-sans-fea-smcp), var(--work-sans-fea-ss02),
    var(--work-sans-fea-ss03), var(--work-sans-fea-ss04),
    var(--work-sans-fea-ss05), var(--work-sans-fea-subs),
    var(--work-sans-fea-sups), var(--work-sans-fea-tnum),
    var(--work-sans-fea-zero), var(--work-sans-fea-cpsp),
    var(--work-sans-fea-kern);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--color-black);
}

button {
  color: inherit;
  border: 0;
  box-shadow: none;
  background: transparent;
  text-shadow: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
}

.button {
  padding: 0.7em 1.2em;
  border-radius: 0.3em;
  transition: color 250ms, background-color 250ms, border 250ms;

  color: inherit;

  /* border: solid 1px var(--gray-dark-color); */
  border: solid 1px var(--color-black);
  /* color: var(--gray-dark-color); */
}

.button:hover:not(:disabled) {
  background-color: var(--blue-color);
  color: white;
  border-color: var(--blue-color);
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 0.2em;
  text-wrap: balance;
}

h1,
.h1 {
  font-size: 1.6rem;
}

h2,
.h2 {
  font-size: 1.5rem;
}

h3,
.h3 {
  font-size: 1rem;
}

label {
  display: block;
  margin-bottom: 0.3em;
}

textarea,
input {
  display: block;
  width: 100%;
  resize: none;
  padding: 0.5em;
  margin: 0;
}

textarea {
  text-indent: inherit;
  text-rendering: inherit;
  text-transform: inherit;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.textarea {
  border: none;
  background: none;
  box-shadow: none;
  box-sizing: inherit;
  outline: none;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-feature-settings: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  tab-size: inherit;

  text-indent: inherit;
  text-rendering: inherit;
  text-transform: inherit;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.small {
  font-size: 0.8em;
}

textarea + button,
input + button {
  margin-top: 0.5em;
}
input + input {
  margin-top: 0.5em;
}

ul {
  list-style: none;
  padding: 0;
}
