.shortcut-helpOLD {
  position: relative;
  display: inline-block;
  width: 0;
  transform: translateX(0.3em);

  opacity: 0;
  transition: opacity 0.1s;
}

.app--hide-keyboard .shortcut-helpOLD {
  transition-delay: 0;
}

.app--show-keyboard .shortcut-helpOLD {
  display: inline-block;
  opacity: 1;
}

.shortcut-helpOLD__inner {
  display: inline-block;
  position: relative;

  /* position: absolute;
  left: 0;
  top: 0; */
  /* padding: 0.1em; */
  /* border-radius: 0.3em; */
  color: white;
  /* transform: translateY(-100%); */
}

.shortcut-helpOLD__inner::before {
  background-color: var(--blue-color);
  border-radius: 0.3em;
  content: "";
  position: absolute;
  left: -0.3em;
  top: -0.3em;
  width: calc(100% + 0.6em);
  height: calc(100% + 0.6em);
}

.shortcut-helpOLD__text {
  /* z-index: 9989898; */
  position: relative;
}
