.column__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.column__head,
.column__footer {
  flex-shrink: 0; /* Prevent these from shrinking */
}

.column__head--split {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.column__body {
  flex-grow: 1; /* Grow to fill the space */
  overflow-y: auto; /* Add scroll for overflow content */
}
