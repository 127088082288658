.editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor__header {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: var(--gray-color);

  /* border-bottom: 1px solid var(--gray-color); */
}
.editor__header-inner {
  margin: 0 auto;
  max-width: var(--layout-max-width);
  display: flex;
  /* gap: 1rem; */
  justify-content: space-between;

}

.editor__content {
  flex: 1;
  overflow: hidden;
  margin: 0;
  position: relative;
}
.editor__content-inner {
  width: 40rem;
  margin: 0 auto;
}

.editor__footer {
  background-color: var(--gray-color);
}
