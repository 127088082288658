/* Overlay.css */

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 800px;
}

.overlay__columns {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  gap: 1rem;
  height: 100%;
}

.overlay_column {
  width: 40%;
}

.overlay__textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;

  /* border-radius: 4px; */
  /* resize: vertical; */
}

ins,
del {
  text-decoration: none;
  /* padding-left: 0.1rem;
  padding-right: 0.1rem; */
}

ins {
  background: #d6ffd6;
}

del {
  background: #ffe6e6;
}

.overlay_column__buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.overlay_column__text {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: calc(100% - 2rem);
}

.text_view {
  font-size: 0.9em;
  text-indent: inherit;
  text-rendering: inherit;
  text-transform: inherit;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
}
