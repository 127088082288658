.counter {
  font-family: "IBM Plex Mono", monospace;
  display: flex;
  flex-wrap: nowrap;
  /* justify-content: space-between; */
  justify-content: flex-start;
  gap: 1rem;
  font-size: 0.8em;
  margin-left: 2em;
}

.counter__group {
}

.counter__group-title {
  font-weight: var(--work-sans-weight-bold);
}

.counter__group-text {
}
